exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-all-offline-table-js": () => import("./../../../src/pages/dashboard/all-offline-table.js" /* webpackChunkName: "component---src-pages-dashboard-all-offline-table-js" */),
  "component---src-pages-dashboard-all-online-table-js": () => import("./../../../src/pages/dashboard/all-online-table.js" /* webpackChunkName: "component---src-pages-dashboard-all-online-table-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-login-js": () => import("./../../../src/pages/dashboard/login.js" /* webpackChunkName: "component---src-pages-dashboard-login-js" */),
  "component---src-pages-dashboard-offline-table-js": () => import("./../../../src/pages/dashboard/offline-table.js" /* webpackChunkName: "component---src-pages-dashboard-offline-table-js" */),
  "component---src-pages-dashboard-offline-table-select-js": () => import("./../../../src/pages/dashboard/offline-table-select.js" /* webpackChunkName: "component---src-pages-dashboard-offline-table-select-js" */),
  "component---src-pages-dashboard-online-table-js": () => import("./../../../src/pages/dashboard/online-table.js" /* webpackChunkName: "component---src-pages-dashboard-online-table-js" */),
  "component---src-pages-dashboard-online-table-select-js": () => import("./../../../src/pages/dashboard/online-table-select.js" /* webpackChunkName: "component---src-pages-dashboard-online-table-select-js" */),
  "component---src-pages-dashboard-valutazione-in-presenza-js": () => import("./../../../src/pages/dashboard/valutazione-in-presenza.js" /* webpackChunkName: "component---src-pages-dashboard-valutazione-in-presenza-js" */),
  "component---src-pages-dashboard-valutazione-js": () => import("./../../../src/pages/dashboard/valutazione.js" /* webpackChunkName: "component---src-pages-dashboard-valutazione-js" */),
  "component---src-pages-file-upload-test-js": () => import("./../../../src/pages/file-upload-test.js" /* webpackChunkName: "component---src-pages-file-upload-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iscrizione-concorso-in-presenza-js": () => import("./../../../src/pages/iscrizione-concorso-in-presenza.js" /* webpackChunkName: "component---src-pages-iscrizione-concorso-in-presenza-js" */),
  "component---src-pages-iscrizione-concorso-online-js": () => import("./../../../src/pages/iscrizione-concorso-online.js" /* webpackChunkName: "component---src-pages-iscrizione-concorso-online-js" */),
  "component---src-pages-iscrizioni-chiuse-js": () => import("./../../../src/pages/iscrizioni-chiuse.js" /* webpackChunkName: "component---src-pages-iscrizioni-chiuse-js" */),
  "component---src-pages-regolamento-concorso-in-presenza-js": () => import("./../../../src/pages/regolamento-concorso-in-presenza.js" /* webpackChunkName: "component---src-pages-regolamento-concorso-in-presenza-js" */),
  "component---src-pages-regolamento-concorso-online-js": () => import("./../../../src/pages/regolamento-concorso-online.js" /* webpackChunkName: "component---src-pages-regolamento-concorso-online-js" */),
  "component---src-pages-risultati-js": () => import("./../../../src/pages/risultati.js" /* webpackChunkName: "component---src-pages-risultati-js" */),
  "component---src-pages-testjson-js": () => import("./../../../src/pages/testjson.js" /* webpackChunkName: "component---src-pages-testjson-js" */)
}

